
import {defineComponent, ref} from "vue";
import {mapActions, mapGetters} from "vuex";
import CatalogBuildStatus from "@/components/layouts/catalog/CatalogBuildStatus.vue";
import ProductFilters from "@/components/layouts/catalog/product-filter/ProductFilters.vue";
import {MultilingualProductLabelRestDto, ProductFilterRestDto} from "@/api/pharma-cpc-mgmt/models";
import {generateUuid} from "@/helpers/functions/string";
import {generateProductFilterPreviewUrl} from "@/helpers/functions/route";
import {PageDetailModeEnum} from "@/models/enum/PageDetailModeEnum";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {ProductFilterApplyTypeEnum} from "@/models/enum/ProductFilterApplyTypeEnum";
import {mapActions as mapActionsP} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import DiscountPricelistSection from "@/components/layouts/catalog/discounts-pricelists/DiscountPriceListSection.vue";
import ProductLabel from "@/components/layouts/catalog/product-labels/ProductLabel.vue";
import {I18nContext} from "@/context/I18nContext";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import BaseOffcanvasButtonOpen from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvasButtonOpen.vue";
import BaseOffcanvas from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvas.vue";
import {GlobalFilterExcludeConditionsRestDtoModel} from "@/models/api/pharma-cpc-mgmt/GlobalFilterExcludeConditionsRestDtoModel";
import RenderedProductLabel from "@/components/layouts/catalog/product-labels/RenderedProductLabel.vue";
import {ColorSchemeRestDtoModel} from "@/models/api/pharma-cms-color-scheme/ColorSchemeRestDtoModel";
import {ColorSchemeRestService} from "@/services/rest/cms-color-scheme/ColorSchemeRestService";
import {ProductLabelRestService} from "@/services/rest/cpc-mgmt/ProductLabelRestService";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {useToast} from "vue-toastification";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";

export default defineComponent({
  name: "ProductFiltersDetail",
  components: {
    AlertError2,
    RenderedProductLabel,
    BaseOffcanvas,
    BaseOffcanvasButtonOpen,
    MultilingualSwitcher,
    ProductLabel,
    DiscountPricelistSection,
    BaseTitle,
    ProductFilters,
    CatalogBuildStatus},
  props: ["mode"],
  data() {
    return {
      code: this.$route.params.code ?? generateUuid() as string,
      toaster: useToast(),
      pageUI: UIStateDto.createWithDefaults(),
      returnRouteObject: {name: 'product-filters'} as object,
      activeLanguage: I18nContext.getDefaultApiLanguageKey(),
      //Hack: and errors object has to be passed to the MultilingualSwitcher, but I can't use the errors object from the form,
      // because this switcher is not inside the form.
      languageSwitchErrors: ref([]),
      productFilter: null as ProductFilterRestDto | null,
      productLabel: null as MultilingualProductLabelRestDto | null,
      colorScheme: ColorSchemeRestDtoModel.createWithDefaults(),
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ProductFilterApplyTypeEnum() {
      return ProductFilterApplyTypeEnum
    },
    PageDetailModeEnum() {
      return PageDetailModeEnum
    },
    ...mapGetters("cpc_mgmt", ["getComplexProductFilter"]),
    previewUrl(): string | null {
      if (this.code) {
        return generateProductFilterPreviewUrl(this.code as string);
      }

      return null;
    }
  },
  methods: {
    ...mapActions("cpc_mgmt", [
      "clearComplexProductFilter",
      "deleteProductFilter",
      "searchComplexProductFilter",
      "saveComplexProductFilter"
    ]),
    ...mapActionsP(useCustomerStore, ["searchAllBrandsCustomer", "searchAllTrademarksCustomer", "searchAllCategoriesCustomer", "searchProductCategoriesTreeCustomer"]),
    async afterSave() {
      if (this.mode === 'new') {
        await this.$router.push(this.returnRouteObject);
      } else {
        window.scrollTo(0, 0);
        await this.reloadContent();

        this.toaster.success(this.$t('savedSuccessfully'));
      }
    },
    async reloadContent() {
      this.pageUI
        .clearError()
        .setNotReady();

      try {
        if (this.mode === 'new') {
          this.clearComplexProductFilter();
          await Promise.all([
            this.searchAllBrandsCustomer(),
            this.searchAllTrademarksCustomer(),
            this.searchAllCategoriesCustomer(),
            this.searchProductCategoriesTreeCustomer()
          ]);
        } else {
          await Promise.all([
            this.searchComplexProductFilter(this.code),
            this.searchAllBrandsCustomer(),
            this.searchAllTrademarksCustomer(),
            this.searchAllCategoriesCustomer(),
            this.searchProductCategoriesTreeCustomer()
          ]);
        }
        this.productFilter = this.getComplexProductFilter;
        if (this.productFilter?.code === 'DELIVERY_BY_SHIPPING_SUPPORTED') {
          if (!this.productFilter.global_exclude_conditions) {
            this.productFilter.global_exclude_conditions = GlobalFilterExcludeConditionsRestDtoModel.createWithDefaults();
          }
        }
        this.colorScheme = await ColorSchemeRestService.getInstance().findColorScheme();
        await this.labelUpdated();
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    async submitData() {
      try {
        await this.saveComplexProductFilter(this.code);
        await this.afterSave();
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    async labelUpdated() {
      this.pageUI
        .clearError()
        .setNotReady();
      try {
        const labelResult = await ProductLabelRestService.getInstance().getProductLabelForProductFilter(this.code);
        if (labelResult) {
          this.productLabel = labelResult.label;
        } else {
          this.productLabel = null;
        }
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    },
  }
});
