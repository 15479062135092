import {RestService} from "@/services/rest/RestService";
import {CacheService} from "@/services/cache/CacheService";
import {CacheKeyEnum} from "@/services/cache/CacheKeyEnum";
import {ProductCategoryAPIApi, ProductTrademarkAPIApi} from "@/api/pharma-cpc-product";
import {CodeDescriptionRestDto, ProductCategoryOverviewRestDto, ProductCategoryRestDto} from "@/api/pharma-cpc-product/models";

export class ProductRestService extends RestService {

    private trademarkApiService: ProductTrademarkAPIApi;
    private categoryApiService: ProductCategoryAPIApi;

    protected constructor() {
        super();

        this.trademarkApiService = new ProductTrademarkAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().cpcProductUrl);
        this.categoryApiService = new ProductCategoryAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().cpcProductUrl);
    }

    public async getAllTrademarks(customerCode: string): Promise<Array<CodeDescriptionRestDto>> {
        let result: Array<CodeDescriptionRestDto> =
            CacheService.getInstance().get(CacheKeyEnum.PRODUCT_TRADEMARKS, customerCode);
        if (result) {
            return result;
        }

        const response = await this.trademarkApiService.findProductTrademarks(customerCode);
        result = response.data;
        CacheService.getInstance().set(CacheKeyEnum.PRODUCT_TRADEMARKS, result, customerCode);

        return result;
    }

    public async getAllCategoriesCustomer(customerCode: string): Promise<Array<ProductCategoryOverviewRestDto>> {
        let result: Array<ProductCategoryOverviewRestDto> = CacheService.getInstance().get(CacheKeyEnum.ALL_CATEGORIES, customerCode);
        if (result) {
            return result;
        }

        const response = await this.categoryApiService.findProductCategories(customerCode);
        result = response.data;
        CacheService.getInstance().set(CacheKeyEnum.ALL_CATEGORIES, result, customerCode);

        return result;
    }

    public async getProductCategoriesTreeCustomer(customerCode: string): Promise<ProductCategoryRestDto> {
        let result: ProductCategoryRestDto = CacheService.getInstance().get(CacheKeyEnum.PRODUCT_CATEGORIES_TREE, customerCode);
        if (result) {
            return result;
        }

        const response = await this.categoryApiService.findProductCategoriesTree(customerCode);
        result = response.data;
        CacheService.getInstance().set(CacheKeyEnum.PRODUCT_CATEGORIES_TREE, result, customerCode);

        return result;
    }
}
